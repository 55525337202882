// colors grabbed from inVision app not already present here
$colorThemeBlue: #3570a1;
$colorCardBlue: rgba(11, 120, 208, 0.1);

// colors specified by Debbie (may duplicate existing colors)
$colorBlue: #0b78d0;
$colorLightBlue: #0b78d061;
$colorLighterBlue: #0b78d026;
$colorLightestBlue: #0b78d00f;
$colorGreen: #6fa135;
$colorGray: #808080;
$colorLightGray: #0000008a;
$colorLighterGray: #00000061;
$colorLightestGray: #fafafa;
$colorWhite: #ffffff;

// colors from the old Bardavon Product UI Guide
$colorPrimary: #1565c0;
$colorAccent: #0b78d0;
$colorHighlight: #6fa136;
$colorAppBar: #f3f3f4;
$colorPrimaryText: rgba(0, 0, 0, 0.54);
$colorSecondaryText: #757575;
$colorDivider: #e6e7e8;
$colorBodyBackground: #fafafa;

// supplemental colors
$colorCardBackground: #fff;
$colorInputBackground: #fff;
$colorListBackground: #f4f4f4;
$colorListStripes: #fcfcfc;
$colorListActive: #fafafa;
$colorListHover: #eee;
$colorActiveNavBg: #4383cc;
$colorButtonBlue: #358cfa;
$colorRowHighlight: #f3f9ec;
$colorListHighlight: #cfe5f9;
$colorAppBarAlt: #b3d4fc;
$colorAppBarAccent: #d6d6d7;
$colorValueBarBg: #e7eff8;
$colorPickerBg: #f2f5fc;
$colorHistoryCardBg: #f0f6fc;
$colorInputLine: #dcdcdc;
$colorMediumText: #646464;
$colorNavDivider: #ffffff;
$colorAccent2: #4a90e2;
$colorInfo: #7ed321;
$colorWarning: #FFCC29;
$colorError: #b71c1c;
$colorProgress: #327bc8;
$colorTabText: #808080;
$colorAppBarBorder: #b4b4b4;
$colorDashboardBorder: #aeafb0;
$colorAsideBackground: rgba(111, 161, 54, 0.1);
$colorLightGreyBorder: #e0e0e0;
$colorLightGreyBackground: #fafafa;
$colorLightGreyBackgroundEmptyState: #f1f1f1;
$colorLightBlueBackground: #cfe5f9;
$colorDarkerGreyBackground: #959595;
$colorRequiredFieldRed: #ba2e1c;

//Named colors
$bardGreen: #6fa136;
$bardBlue600: #0878d0;
$bardRed800: #ba3412;


//Rerral app colors
$colorReferralBlue: #3470A1;
$colorReferralBlack: #000000;

// font sizes
$fontSizeXxxsmall: 10px;
$fontSizeXxsmall: 11px;
$fontSizeXsmall: 12px;
$fontSizeSmall: 13px;
$fontSizeNormal: 14px;
$fontSizeLarge: 16px;
$fontSizeXL: 18px;
$fontSize2XL: 20px;
$fontSize3XL: 24px;
$fontSize4XL: 30px;

// layout
$appbarHeight: 20px;
$emptySpacer: 10px;

// miscellaneous
$disabledOpacity: 0.5;
$dockActionWidth: 20px;
$dockIconWidth: 60px;
$fontFamily: Roboto, Helvetica, Arial, sans-serif;
$lineHeight: 24px;
$spacing: 8px;