@use "../../../variables.scss";


.submitUploadWarningDialog {
  div {
    &:global(.MuiPaper-root) {
      width: 600px;
      max-width: 100%;
    }
  }
  h2 {
    color: #3571a1;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.1px;
  }
  p {
    color: variables.$colorLightGray;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 50px;
  }
  button {
    margin-bottom: 20px;
    margin-right: 16px;
  }
}

.submitValidationDialog {
    div {
      &:global(.MuiPaper-root) {
        width: 600px;
        max-width: 100%;
      }
    }
    h2 {
      font-size: 24px !important;
      font-weight: 600;
      letter-spacing: -0.1px;
    }
    p {
      margin-top: 0;
      margin-bottom: 40px !important;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .submitErrorDialog {
    div {
      &:global(.MuiPaper-root) {
        width: 600px;
        max-width: 100%;
      }
    }
    h2 {
      color: #b71b1c;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.1px;
    }
    p {
      margin-top: 0;
      margin-bottom: 50px;
    }
    button {
      margin-bottom: 20px;
    }
  }