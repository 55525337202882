@use "./variables";

.fontLargeBold {
  &:global(.MuiTypography-body1) {
    font-size: variables.$fontSizeLarge;
    font-weight: 600;
  }
}

.fontLargeNormal {
  &:global(.MuiTypography-body1) {
    font-size: variables.$fontSizeLarge;
    font-weight: 500;
  }
}

.fontLargeSecondary {
    font-size: variables.$fontSizeLarge;
    font-weight: 500;
    color: variables.$colorLightGray;
}

.fontNormalSecondary {
    font-size: variables.$fontSizeNormal;
    font-weight: 500;
    color: variables.$colorLightGray;
}

.divider {
  border-top: 1px solid black;
}

.hidden {
  visibility: hidden;
}