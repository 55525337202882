@use "../../variables.scss";

.reviewForm {
  .link {
    font-size: variables.$fontSizeLarge;
    color: #0a79ce;
    text-decoration: none;
    &:visited {
      color: "";
    }
    &:hover,
    &:active {
      color: #0a79ce;
      text-decoration: none;
    }
  }
  .leftAlign {
    display: flex;
    justify-content: flex-start;
  }
  .divider {
    border-top: 1px solid variables.$colorLightGray;
    width: 850px;
  }
  // Adding these classes inside the table class ensures these properties are higher priority than mui's table classes
  .table {
    .cell {
      border: none;
      padding: none;
      white-space: nowrap;
      color: variables.$colorSecondaryText;
      font-size: 14px;
      &:global(.MuiTableCell-sizeSmall) {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .leftCell {
      border: none;
      padding: none;
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
      color: #212121;
      &:global(.MuiTableCell-sizeSmall) {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
    
    .emptyHeader {
      height: 0px;
      border: none;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .forceMaxWidth {
    width: 178px;
  }

  .MuiTableCell-root {
    border-bottom: none !important;
  }
  .missingInfoText {
    vertical-align: middle;
    color: #b71c1c;
    margin-left: 10px !important;
  }

  .fileSeparator {
    margin: 0 calc(variables.$spacing/2) 0 calc(variables.$spacing/2)
  }
}
