@use "../variables.scss";

.appBar {
  border-radius: 0;
  padding-bottom: 0;
  height: variables.$appbarHeight;
  &:global(.MuiAppBar-colorPrimary) {
    background: variables.$colorThemeBlue;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 calc(variables.$spacing * 12) 0;
  max-width: 1425px;
  align-items: center;
}

.logoContainer {
  margin-top: variables.$spacing;
  margin-bottom: variables.$spacing;
  padding-top: calc(variables.$spacing * 4);
  padding-bottom: calc(variables.$spacing * 2);
  cursor: pointer;
}

.logo {
  width: 280px;
  height: 80px;
  margin-left: 0px;
  margin-top: -1rem;
  background-color: variables.$colorBodyBackground;
}

// Dynamically set minimum left margin based on screen size
@media (max-width: 1600px) {
  .container {
    margin-left: 80px;
    margin-right: 0px;
  }
}
@media (max-width: 1450px) {
  .container {
    margin-left: 70px;
    margin-right: 0px;
  }
}
@media (max-width: 1300px) {
  .container {
    margin-left: 65px;
    margin-right: 0px;
  }
}
@media (max-width: 1250px) {
  .container {
    margin-left: 55px;
    margin-right: 0px;
  }
}
@media (max-width: 1000px) {
  .container {
    margin-left: 48px;
    margin-right: 0px;
  }
}
@media print {
  .appBar {
    display: None;
  }
  .container {
    width: 8in;
    padding: 0 0.75in;
  }
}
