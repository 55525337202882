@use '../../variables.scss';
//TODO: Remove inline styles

.link {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    &:global(.MuiTypography-root) {
        margin-top: 15px;
    }
    &:global(.MuiTypography-colorPrimary) {
        color: variables.$colorThemeBlue;
    }
}

.printLinkContainer > .link:first-child {
    margin: 15px 50px 0px 8px;
}

.printLinkContainer {
    display: flex;
    align-items: center;
}