@use '../../variables.scss';

.condensedReview {
        .flexContainer {
            display: grid;
            gap: 0px;
        }

        .flexHalf > .subsection ~ .subsection {
            margin-top: 17px;
        }

        .subsection {
            grid-column: 1 / span 2;
            color: variables.$colorGreen;
            font-size: variables.$fontSizeNormal;
            font-weight: 500;
        }

        .sectionHeader {
            color: variables.$colorGreen;
            font-size: variables.$fontSize2XL;
            font-weight: 500;
            margin-bottom: 8px;
            margin-top: 8px;
            grid-column: 1 / span 2;
        }

        .flexLabel {
            font-weight: 600;
            font-size: 14px;
            color: #212121;
            grid-column: 1;
        }

        .flexValue {
            border: none;
            padding: none;
            color: variables.$colorSecondaryText;
            font-size: 14px;
            font-weight: 400;
            grid-column: 2;
            margin-bottom: 8px;
            min-width: 200px;
        }

        .flexHalf {
             display: grid;
             grid-template-columns: max-content auto;
             break-inside: avoid;
             page-break-inside: avoid;
             column-gap: 10px;
             max-width: max(30vw, 700px);
             align-content: flex-start;
        }

        .flexHalf:only-child {
            grid-template-columns: max-content minmax(min-content, 550px);
        }

        @media (min-width: 740px) {
            .completedPage.flexContainer {
                grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
                gap: 60px;
            }
        
            .completedPage.flex-section {
                grid-template-columns: max-content min(400px, calc(50vw - 220px));
            }
        
        }

        @media print {
            .flexHalf {
                break-inside: auto;
                page-break-inside: auto;
            }
        }

    
}